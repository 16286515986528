@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins";
  font-weight: 300;
}

.poppins-700 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
}
.poppins-600 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
}
.poppins-500 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
}
.poppins-400 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
}
.poppins-300 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
}

.borderBox {
  box-sizing: border-box;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
}

.text-blue {
  color: #125d98;
}

.bg-blue {
  background: #125d98;
}
.bg-blue-light {
  background: #d9ecf2;
}

.bg-custom {
  background: #8fadc8;
}
.bg-sidebar {
  background: #dae2ea;
}

.mt-10-px {
  margin-top: 6.5px;
}

.w-60-px {
  width: 60px;
}
.mt-17 {
  margin-top: 4.5rem;
}

.dialpad {
  width: 4.5rem;
  height: 4.5rem;
}

.w-97 {
  width: 30rem;
}

.w-1\/14 {
  width: 10%;
}
.max-h-lg {
  max-height: 20.5rem;
}
/* input:focus-within ~ label {
  @apply transform scale-75 -translate-y-6 text-blue-500;
} */

.top-2\/5 {
  top: 60%;
}
.Iinput:focus-within ~ label,
.Iinput:not(:placeholder-shown) ~ label {
  @apply transform scale-75 -translate-y-6;
}
/* .Iinput:focus-within ~ label {
  @apply text-blue-500;
} */

@media (max-width: 450px) {
  .s\:hidden {
    display: none;
  }
  .s\:-top-9 {
    /* top: -9rem; */
  }
  .s\:top-2\/4 {
    top: -9rem;
  }
}
@media (min-width: 450px) {
  .sd\:-top-9 {
    top: -9rem;
  }
}
@media (max-width: 640px) {
  .smd\:-top-50 {
    top: -8rem;
  }
}

@media (max-width: 1024px) {
  .lgd\:hidden {
    display: none;
  }
}
.tooltip {
  visibility: hidden;
  position: absolute;
}
.has-tooltip:hover .tooltip {
  visibility: visible;
  z-index: 100;
}
.ml-65 {
  margin-left: 16.5rem;
}

.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68d391;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #68d391;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
